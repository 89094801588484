<template>
  <div>
    <a-modal v-model="visible" width="800px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="8" style="width: 200px">
              <a-form-model-item prop="warehouse" label="仓库">
                <a-select v-model="form.warehouse" style="width: 100%">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" style="width: 200px; margin: 0 20px">
              <a-form-model-item prop="handler" label="经手人">
                <a-select v-model="form.handler" style="width: 100%">
                  <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" style="width: 280px">
              <a-form-model-item prop="handle_time" label="领料日期">
                <a-date-picker v-model="form.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">产品信息</a-divider>

        <div>
          <a-row :gutter="16">
            <a-space>
              <a-button type="primary" @click="openMaterialModal">添加产品</a-button>
            </a-space>
          </a-row>
          <div style="margin-top: 16px">
            <a-table rowKey="id" size="middle" :columns="columns" :data-source="goodsData" :pagination="false">
              <div slot="sales_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ value }}</div>
                <a-input-number v-else v-model="item.sales_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="sales_price" slot-scope="value, item, index">
                <a-input-number v-if="!item.isTotal" v-model="item.sales_price" :min="0" size="small"></a-input-number>
              </div>
              <div slot="remark" slot-scope="value, item, index">
                <a-input v-if="!item.isTotal" v-model="item.remark"></a-input>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeMaterial(item)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>
    </a-modal>
    <materials-select-modal
            :visible="materialsSelectModalVisible"
            :warehouse="form.warehouse"
            @select="onSelectMaterial"
    ></materials-select-modal>
  </div>
</template>

<script>
  import moment from "moment";
  import { clientList, clientCreate } from '@/api/basicData'
  import { outboundAdd, outboundEdit } from "@/api/produce";
  import { getSaleOrderNumber } from "@/api/data";
  import { saleOrderCreate } from "@/api/sale";
  import { userOption, clientsOption, warehousesOption, inventoriesOption, accountsOption } from "@/api/option";
  import NP from "number-precision";

  export default {
    props: ["visible", "form", "clientsClassificationOptions"],
    model: { prop: "visible", event: "cancel" },
    components: {
      MaterialsSelectModal: () => import("@/components/MaterialSelectModal/index"),
    },
    data() {
      return {
        description: "新增",
        warehouseItems: [],
        handlerItems: [],
        clientsItems: [],
        accountsItems: [],
        materialsSelectModalVisible: false,
        loading: false,
        model: {},
        rules: {
          warehouse: [{ required: true, message: "请选择仓库", trigger: "change" }],
          handler: [{ required: true, message: "请选择经手人", trigger: "change" }],
          handle_time: [{ required: true, message: "请选择领料日期", trigger: "change" }]
        },
        columns: [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? "合计" : index + 1;
            },
          },
          {
            title: "名称",
            dataIndex: "name",
            key: "name",
            width: 150,
          },
          /* {
            title: "编号",
            dataIndex: "number",
            key: "number",
            width: 150,
          }, */
          {
            title: "规格",
            dataIndex: "spec",
            key: "spec",
            width: 150,
          },
          {
            title: "单位",
            dataIndex: "unit",
            key: "unit",
            width: 80,
          },
          {
            title: "领料数量",
            dataIndex: "sales_quantity",
            key: "sales_quantity",
            width: 120,
            scopedSlots: { customRender: "sales_quantity" },
          },
          {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
            scopedSlots: { customRender: "remark" },
          },
          /* {
            title: "销售单价(元)",
            dataIndex: "sales_price",
            key: "sales_price",
            width: 120,
            scopedSlots: { customRender: "sales_price" },
          },
          {
            title: "金额",
            dataIndex: "totalAmount",
            key: "totalAmount",
            width: 200,
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.sales_quantity, item.sales_price);
              return item.id ? NP.round(value, 2) : "";
            },
          }, */
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            width: 80,
            scopedSlots: { customRender: "action" },
          },
        ],
        materialItems: [],
        sales_account_items: [],
        sales_account_item: {},
      };
    },
    created() {
      clientList({ page: 1, page_size: 2000, others: {} }).then(data => {
        let info = data.results || [];
        let val = info.filter(item => item.name === '自家工厂车间');
        if(val.length === 0) {
          clientCreate({ name: '自家工厂车间', number: 'zjgccj', others: {} })
                  .then((res) => {
                    this.form.client = res.id
                  })
        } else {
          this.form.client = val[0].id
        }
      })
      getSaleOrderNumber().then((data) => {
        this.form.number = data.number
      });
    },
    computed: {
      totalAmount() {
        let totalAmount = 0;
        for (let item of this.materialItems) {
          let amount = NP.times(item.sales_quantity, item.sales_price);
          totalAmount = NP.plus(totalAmount, amount);
        }

        totalAmount = NP.times(totalAmount, this.form.discount || 100, 0.01);
        totalAmount = NP.plus(totalAmount, this.form.other_amount || 0);
        return totalAmount;
      },
      amountOwed() {
        return NP.minus(this.totalAmount, this.sales_account_item.collection_amount || 0);
      },
      goodsData() {
        // 统计合计
        let totalQuantity = 0,
                totalAmount = 0;
        for (let item of this.materialItems) {
          totalQuantity = NP.plus(totalQuantity, item.sales_quantity);
          let amount = NP.times(item.sales_quantity, item.sales_price);
          totalAmount = NP.plus(totalAmount, amount);
        }
        return [
          ...this.materialItems,
          {
            id: "-1",
            isTotal: true,
            name: "",
            sales_quantity: totalQuantity,
            totalAmount: `${totalAmount} * ${this.form.discount}% = ${NP.times(totalAmount, this.form.discount || 0, 0.01)}`,
          },
        ];
      },
      accountsData() {
        // 统计合计
        let totalAmount = 0;
        for (let item of this.sales_account_items) {
          totalAmount = NP.plus(totalAmount, item.collection_amount);
        }
        return [
          ...this.sales_account_items,
          {
            id: "-1",
            isTotal: true,
            collection_amount: totalAmount,
          },
        ];
      },
    },
    methods: {
      confirm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true;
            let func = this.form.id ? outboundEdit : saleOrderCreate;
            this.create()
            func(this.form)
                    .then(() => {
                      this.$message.success(this.form.id ? "修改成功" : "新增成功");
                      this.$emit(this.form.id ? "update" : "create");
                      this.cancel();
                    })
                    .finally(() => {
                      this.loading = false;
                    });
          }
        });
      },
      cancel() {
        this.$emit("cancel", false);
      },
      moment,
      initData() {
        this.materialItems = (this.form.id && this.form.sales_goods_items) || []
        // this.resetForm();
        warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
          this.warehouseItems = data.results;
          console.log(this.warehouseItems);
        });
        userOption({ page_size: 999999, is_active: true }).then((data) => {
          this.handlerItems = data.results;
        });
        clientsOption({ page_size: 999999, is_active: true }).then((data) => {
          this.clientsItems = data.results;
        });
        accountsOption({ page_size: 999999, is_active: true }).then((data) => {
          this.accountsItems = data.results;
        });
      },
      handelAddAcount() {
        this.sales_account_items.push({
          id: this.sales_account_items.length + 1,
          account: "",
          collection_amount: 0,
        });
      },
      removeAccount(item) {
        this.sales_account_items = this.$functions.removeItem(this.sales_account_items, item);
      },
      changeAccount(value, item, idx) {
        let count = this.sales_account_items.filter((_item) => {
          return _item.account == value;
        });
        if (count.length > 1) {
          this.$message.warn("已添加过改结算账户!");
          this.sales_account_items[idx].account = "";
        }
      },
      openMaterialModal() {
        if (!this.form.warehouse) {
          this.$message.warn("请先选择仓库！");
          return false;
        }
        this.materialsSelectModalVisible = true;
      },
      onSelectMaterial(item) {
        let index = this.materialItems.findIndex((_item) => _item.id == item.id);

        if (index != -1) {
          this.$message.warn("产品已存在");
          return;
        }
        this.materialItems = this.$functions.insertItem(this.materialItems, {
          id: item.id,
          goods: item.goods,
          number: item.goods_number,
          name: item.goods_name,
          spec: item.goods_spec,
          unit: item.unit_name,
          sales_quantity: 1,
          remark: item.remark,
          sales_price: item.retail_price,
          totalAmount: 1,
        });

        this.materialItems = [...this.materialItems];
      },
      removeMaterial(item) {
        this.materialItems = this.$functions.removeItem(this.materialItems, item);
      },
      create() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let ifHasEmptyGoods = false;
            let ifHasEmptyAccounts = false;
            if (this.materialItems.length == 0) {
              this.$message.warn("未添加产品");
              return false;
            }
            this.materialItems.map((item) => {
              if (item.sales_price === null || !item.sales_quantity) {
                ifHasEmptyGoods = true;
              }
            });
            if (ifHasEmptyGoods) {
              this.$message.warn("销售单价和销售数量必填");
              return false;
            }

            let sales_account_items = [];
            if (this.sales_account_item.account && this.sales_account_item.collection_amount > 0) {
              sales_account_items = [this.sales_account_item];
            }

            this.loading = true;
            let formData = {
              ...this.form,
              sales_account_items
            };
            this.form = formData;
            this.form.sales_goods_items = this.materialItems.map((item) => {
              return {
                goods: item.goods,
                sales_quantity: item.sales_quantity,
                sales_price: item.sales_price,
                remark: item.remark,
              };
            })
          }
        });
      },
      resetForm() {
        this.form = { other_amount: 0 };
        this.sales_account_item = { collection_amount: 0 };
        this.form = { ...this.form, discount: 100 };
        this.materialItems = [];
        this.handelAddAcount();
      },
    },
    mounted() {
      this.initData();
    },
  };
</script>
